import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import * as Chakra from '@chakra-ui/react'
import DeployToNetlify from '../images/button.svg'
import {Seo} from "../components/seo";

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div>
      <Chakra.Flex
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `top left`,
        backgroundAttachment: `fixed`,
      }}
      width="100%"
      minHeight="50vh"
      alignItems="center"
      flexDir="column"
      justifyContent="flex-end"
      p="40px"
    >
        <Chakra.Heading color="#fff" pb="10px" letterSpacing="-1.3px">{heading}</Chakra.Heading>
        <Chakra.Heading size="sm" color="#eee" letterSpacing="-0.11px">{subheading}</Chakra.Heading>
    </Chakra.Flex>
      <Chakra.Box px="40px">
          <Chakra.Flex flexDirection="column" alignItems="center" justifyContent="center" py="80px" maxWidth="600px" m="auto">
              <Chakra.Heading pb="10px" textAlign="center" letterSpacing="-1.3px">{mainpitch.title}</Chakra.Heading>
              <Chakra.Text size="sm" textAlign="center" letterSpacing="-0.11px">{mainpitch.description}</Chakra.Text>
              <Chakra.Link mt="20px" href="https://app.netlify.com/start/deploy?repository=https://github.com/audal/audal-boilerplate">
                  <Chakra.Box as={DeployToNetlify} />
              </Chakra.Link>
          </Chakra.Flex>
          <Chakra.Divider/>
          <Chakra.Flex flexDirection="column" alignItems="center" justifyContent="center" pb="80px" m="auto">
              <Chakra.Button mt="60px" as={Link} className="btn" to="/guide">
                  Read the guides
              </Chakra.Button>
          </Chakra.Flex>
      </Chakra.Box>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark

  return (
      <>
          <Seo title={frontmatter.title} location={location} />
          <IndexPageTemplate
              image={frontmatter.image}
              title={frontmatter.title}
              heading={frontmatter.heading}
              subheading={frontmatter.subheading}
              mainpitch={frontmatter.mainpitch}
              description={frontmatter.description}
              intro={frontmatter.intro}
          />
      </>

  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
      }
    }
  }
`
